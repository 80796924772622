import React, { useCallback, useState } from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import styled from 'styled-components';
import {
  LeaderboardItem,
  SortDirection,
  LeaderboardSortKey,
  LEADERBOARD_SORT_KEY_MAPPING,
  LeaderboardSortCondition,
} from 'type';

import { Flag } from 'components/Flag';
import SVGIcon from 'components/SVGIcon';
import Table from 'components/Table';
import { Time } from 'components/Time';

// 相対時間の有効化
dayjs.extend(relativeTime);

type LeaderboardProps = {
  className?: string;
  country: string;
  items: LeaderboardItem[];
  highlightedUser: string;
  setHighlightedUser: (userName: string) => void;

  // 国別ランキングを表示するか
  showLocalRank: boolean;

  // 詳細表示に関するもの
  showDetail: boolean;

  // ソート関連
  sortCondition: LeaderboardSortCondition;
  setSortCondition: (sortCondition: LeaderboardSortCondition) => void;

  // 表示するプレイヤー
  players?: string[];
};

const getRankForDisplay = (
  item: LeaderboardItem,
  key: 'rank' | 'provrank' | 'localrank' | 'provlocalrank' | 'customrank' | 'provcustomrank',
) => {
  const rankKey = key.replace('prov', '');
  const provRankKey = key;
  const isProvisional = key.startsWith('prov');

  const rank = item[LEADERBOARD_SORT_KEY_MAPPING[rankKey]];
  const provRank = item[LEADERBOARD_SORT_KEY_MAPPING[provRankKey]];

  // 暫定でない順位の場合
  if (!isProvisional) {
    return rank || '';
  }

  // 暫定順位の場合
  if (provRank === null) {
    return '';
  }

  if (provRank === rank) {
    return provRank;
  }

  return `( ${provRank} )`;
};

const quote = (value: any) => `'${value}'`;

export const Leaderboard: React.FC<LeaderboardProps> = ({
  className,
  country,
  items,
  highlightedUser,
  setHighlightedUser,
  showLocalRank,
  showDetail,
  sortCondition,
  setSortCondition,
  players,
}) => {
  const [sortKey, sortDirection] = sortCondition;

  const setSortKey = useCallback(
    (newSortKey: LeaderboardSortKey) => {
      // 世界順位の暫定順位は国が"World"の場合だけ表示する
      if (newSortKey === 'rank') {
        if (!['rank', 'provrank'].includes(sortKey)) {
          setSortCondition([newSortKey, 'asc']);
          return;
        }

        let nextSortKey: LeaderboardSortKey | null = null;
        if (sortDirection === 'desc') {
          if (sortKey === 'rank') {
            nextSortKey = 'provrank';
          } else {
            nextSortKey = 'rank';
          }

          setSortCondition([nextSortKey, null]);
          return;
        }

        switch (sortDirection) {
          case 'asc':
            setSortCondition([sortKey, 'desc']);
            return;
          case null:
            setSortCondition([sortKey, 'asc']);
            return;
        }
      }

      if (sortKey !== newSortKey) {
        setSortCondition([newSortKey, 'asc']);
        return;
      }

      switch (sortDirection) {
        case 'asc':
          setSortCondition([newSortKey, 'desc']);
          return;
        case 'desc':
          setSortCondition([newSortKey, null]);
          return;
        case null:
          setSortCondition([newSortKey, 'asc']);
          return;
      }
    },
    [sortKey, sortDirection, setSortCondition],
  );

  const [isRelativeDate, setIsRelativeDate] = useState(true);

  // 暫定順位を表示するか
  const showProvisionalRank = sortKey === 'provrank';

  // カスタムランキングか
  const isCustomRanking = players !== undefined && players.length > 0;

  return (
    <StyledTable className={className}>
      <thead>
        <SpacerHeaderRow />
        <StickyHeaderRow>
          <Table.Header />
          <Table.Header style={{ minWidth: 70 }} colSpan={showDetail ? 2 : 1}>
            <ClickableHeaderLabel
              onClick={() => setSortKey('rank')}
              sortDirection={['rank', 'provrank'].includes(sortKey) ? sortDirection : null}
              pos={sortKey === 'rank' ? 'before' : 'after'}
            >
              <Flag country="World" />
            </ClickableHeaderLabel>
          </Table.Header>
          {showLocalRank && (
            <Table.Header style={{ minWidth: 50 }} colSpan={showDetail ? 2 : 1}>
              <ClickableHeaderLabel onClick={() => setSortKey('rank')} sortDirection={null}>
                <Flag country={country} />
              </ClickableHeaderLabel>
            </Table.Header>
          )}
          {isCustomRanking && (
            <Table.Header style={{ minWidth: 50 }} colSpan={showDetail ? 2 : 1}>
              <ClickableHeaderLabel onClick={() => setSortKey('rank')} sortDirection={null}>
                ⛏️
              </ClickableHeaderLabel>
            </Table.Header>
          )}
          <Table.Header>
            <ClickableHeaderLabel
              onClick={() => setSortKey('player')}
              sortDirection={sortKey === 'player' ? sortDirection : null}
            >
              Player
            </ClickableHeaderLabel>
          </Table.Header>
          {showDetail && (
            <Table.Header>
              <ClickableHeaderLabel
                onClick={() => setSortKey('rta')}
                sortDirection={sortKey === 'rta' ? sortDirection : null}
              >
                Real time
              </ClickableHeaderLabel>
            </Table.Header>
          )}
          <Table.Header>
            <ClickableHeaderLabel
              onClick={() => setSortKey('igt')}
              sortDirection={sortKey === 'igt' ? sortDirection : null}
            >
              In-game time
            </ClickableHeaderLabel>
          </Table.Header>
          {showDetail && (
            <>
              <Table.Header>
                <ClickableHeaderLabel
                  onClick={() => setSortKey('version')}
                  sortDirection={sortKey === 'version' ? sortDirection : null}
                >
                  Version
                </ClickableHeaderLabel>
              </Table.Header>
              <Table.Header>
                <ClickableHeaderLabel
                  onClick={() => setSortKey('difficulty')}
                  sortDirection={sortKey === 'difficulty' ? sortDirection : null}
                >
                  Difficulty
                </ClickableHeaderLabel>
              </Table.Header>
              <Table.Header>
                <ClickableHeaderLabel
                  onClick={() => setSortKey('f3')}
                  sortDirection={sortKey === 'f3' ? sortDirection : null}
                >
                  F3
                </ClickableHeaderLabel>
              </Table.Header>
              <Table.Header>
                <ClickableHeaderLabel
                  onClick={() => setSortKey('mods')}
                  sortDirection={sortKey === 'mods' ? sortDirection : null}
                >
                  Mods
                </ClickableHeaderLabel>
              </Table.Header>
            </>
          )}
          <Table.MobileHiddenHeader forceVisible={showDetail}>
            <ClickableHeaderLabel
              onClick={() => setSortKey('date')}
              sortDirection={sortKey === 'date' ? sortDirection : null}
            >
              Date
              <DateSwith
                onClick={(event) => {
                  setIsRelativeDate((value) => !value);
                  event.stopPropagation();
                }}
              />
            </ClickableHeaderLabel>
          </Table.MobileHiddenHeader>
          {showDetail && (
            <Table.MobileHiddenHeader forceVisible={showDetail}>
              <ClickableHeaderLabel
                onClick={() => setSortKey('verify_date')}
                sortDirection={sortKey === 'verify_date' ? sortDirection : null}
              >
                Verify date
                <DateSwith
                  onClick={(event) => {
                    setIsRelativeDate((value) => !value);
                    event.stopPropagation();
                  }}
                />
              </ClickableHeaderLabel>
            </Table.MobileHiddenHeader>
          )}
          <Table.Header style={{ width: 16 }} />
        </StickyHeaderRow>
      </thead>
      <tbody>
        {items.map((item) => {
          // プレイヤーフィルターがある場合はフィルタリング
          if (players && !players.includes(item.player.name)) {
            return null;
          }
          // ソート対象がランクで、ソート方向がnull以外の場合、ランクがnullのものは表示しない
          if (sortKey === 'rank' && sortDirection !== null) {
            if (item.rank === null) {
              return null;
            }
          } else if (sortKey === 'provrank' && sortDirection !== null) {
            if (item.provisional_rank === null) {
              return null;
            }
          }
          return (
            <BodyRow
              key={`${item.record.id}`}
              id={item.player.name}
              highlight={!!highlightedUser && item.player.name === highlightedUser}
              unverified={item.rank === null}
            >
              <AnchorData
                style={{ width: 24 }}
                onClick={() => setHighlightedUser(item.player.name !== highlightedUser ? item.player.name : '')}
              >
                <AnchorIcon />
              </AnchorData>
              <>
                {!showDetail ? (
                  <Table.Data>
                    <ExternalLink href={item.record.link}>
                      {getRankForDisplay(item, showProvisionalRank ? 'provrank' : 'rank') || '*'}
                    </ExternalLink>
                  </Table.Data>
                ) : (
                  <>
                    <Table.Data>
                      <ExternalLink href={item.record.link}>{getRankForDisplay(item, 'rank') || '*'}</ExternalLink>
                    </Table.Data>
                    <Table.Data>{getRankForDisplay(item, 'provrank')}</Table.Data>
                  </>
                )}
              </>
              {showLocalRank && (
                <>
                  {!showDetail ? (
                    <Table.Data>
                      {getRankForDisplay(item, showProvisionalRank ? 'provlocalrank' : 'localrank')}
                    </Table.Data>
                  ) : (
                    <>
                      <Table.Data>{getRankForDisplay(item, 'localrank')}</Table.Data>
                      <Table.Data>{getRankForDisplay(item, 'provlocalrank')}</Table.Data>
                    </>
                  )}
                </>
              )}
              {isCustomRanking && (
                <>
                  {!showDetail ? (
                    <Table.Data>
                      {getRankForDisplay(item, showProvisionalRank ? 'provcustomrank' : 'customrank')}
                    </Table.Data>
                  ) : (
                    <>
                      <Table.Data>{getRankForDisplay(item, 'customrank')}</Table.Data>
                      <Table.Data>{getRankForDisplay(item, 'provcustomrank')}</Table.Data>
                    </>
                  )}
                </>
              )}
              <Table.Data>
                {!showLocalRank && <Flag country={item.player.country || ''} />}
                {item.player.name}
              </Table.Data>

              {showDetail && (
                <Table.Data>
                  <Time value={item.record.real_time_t} />
                </Table.Data>
              )}
              <Table.Data>
                <Time value={item.record.in_game_time_t} />
              </Table.Data>
              {showDetail && (
                <>
                  <Table.Data>{item.record.version} </Table.Data>
                  <Table.Data>{item.record.difficulty}</Table.Data>
                  <Table.Data>{item.record.f3}</Table.Data>
                  <Table.Data>{item.record.mods}</Table.Data>
                </>
              )}
              <MobileHiddenDateData forceVisible={showDetail}>
                <Hover>{dayjs(item.record.date).format('YYYY-MM-DD')}</Hover>
                <HoverOut>
                  {isRelativeDate ? dayjs(item.record.date).fromNow() : dayjs(item.record.date).format('YYYY-MM-DD')}
                </HoverOut>
              </MobileHiddenDateData>
              {showDetail && (
                <MobileHiddenDateData forceVisible={showDetail}>
                  {item.record.verify_date !== null ? (
                    <>
                      <Hover>{dayjs(item.record.verify_date).format('YYYY-MM-DD')}</Hover>
                      <HoverOut>
                        {isRelativeDate
                          ? dayjs(item.record.verify_date).fromNow()
                          : dayjs(item.record.verify_date).format('YYYY-MM-DD')}
                      </HoverOut>
                    </>
                  ) : (
                    '---'
                  )}
                </MobileHiddenDateData>
              )}
            </BodyRow>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

const ClickableHeaderLabel: React.FC<{
  onClick: () => void;
  sortDirection?: SortDirection;
  pos?: 'before' | 'after';
}> = ({ onClick, sortDirection, children, pos }) => (
  <ClickableHeaderLabelWrapper onClick={onClick}>
    <ClickableHeaderLabelInner sortDirection={sortDirection} pos={pos}>
      {children}
    </ClickableHeaderLabelInner>
  </ClickableHeaderLabelWrapper>
);

const ClickableHeaderLabelWrapper = styled.div`
  cursor: pointer;
`;
const ClickableHeaderLabelInner = styled.a<{ sortDirection?: SortDirection; pos?: 'before' | 'after' }>`
  text-decoration: none;
  text-decoration-style: dashed;

  position: relative;
  display: inline-block;

  :before {
    content: ${({ sortDirection, pos = 'before' }) =>
      quote(sortDirection == null || pos !== 'before' ? '' : sortDirection === 'asc' ? '▲' : '▼')};
    display: block;
    position: absolute;
    top: 3px;
    left: -12px;
    font-size: 0.75em;
  }

  :after {
    content: ${({ sortDirection, pos = 'before' }) =>
      quote(sortDirection == null || pos !== 'after' ? '' : sortDirection === 'asc' ? '▲' : '▼')};
    display: block;
    position: absolute;
    top: 3px;
    right: -12px;
    font-size: 0.75em;
  }

  ::selection {
    background: transparent;
  }
`;

const Hover = styled.div``;
const HoverOut = styled.div``;

const AnchorIcon = styled(SVGIcon).attrs(() => ({ type: 'link', size: 12 }))`
  visibility: hidden;

  margin-left: 12px;
  svg {
    fill: #666;
  }
`;

const StyledTable = styled(Table)`
  border-collapse: separate;
`;

const SpacerHeaderRow = styled(Table.Row)`
  position: sticky;
  top: 0;
  height: 93px;
  background: #fff;
`;

const StickyHeaderRow = styled(Table.Row)`
  position: sticky;
  top: 93px;
  background: #fff;
  line-height: 2;
  padding: 8px 0;
  user-select: none;

  th {
    border-bottom: 1px solid #999;
  }
`;

const BodyRow = styled(Table.BodyRow)`
  ${Hover} {
    display: none;
  }
  ${HoverOut} {
    display: block;
  }

  &:hover {
    ${AnchorIcon} {
      visibility: visible;
    }
    ${Hover} {
      display: block;
    }
    ${HoverOut} {
      display: none;
    }
  }
`;

const AnchorData = styled(Table.Data)`
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ExternalLink = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noreferrer',
}))`
  text-decoration: underline dotted;
  text-underline-offset: 0.2em;
  text-align: center;
`;

const MobileHiddenDateData = styled(Table.MobileHiddenData)`
  min-width: 150px;
`;

const DateSwith = styled.span`
  position: relative;

  :before {
    content: '⇄';
    display: block;
    position: absolute;
    top: -8px;
    right: -24px;
    padding: 4px;
  }
`;
