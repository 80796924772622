import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';

import { fetchCountries, fetchLeaderboard } from 'api';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import { useLocation, useHistory } from 'react-router-dom';
import Select from 'react-select';
import GlobalStyle from 'style';
import styled from 'styled-components';
import useSWRImmutable from 'swr/immutable';
import {
  CATEGORIES,
  Category,
  CombinedType,
  COMBINED_TYPES,
  DEFAULT_CATEGORY,
  DEFAULT_COMBINED_TYPES,
  DEFAULT_RUN_TYPE,
  DEFAULT_VERSION_RANGE,
  RunType,
  RUN_TYPES,
  VersionRange,
  VERSION_RANGES,
} from 'type';

import { CombinedCountryLeaderboard } from 'components/CombinedCountryLeaderboard';
import { Flag } from 'components/Flag';
import Footer from 'components/Footer';
import LoadingSpinner from 'components/LoadingSpinner';
import { Switch } from 'components/Switch';
import { createLeaderboardPath, getConbinedCountryLeaderboardItems } from 'utils/leaderboard';

const CombinedCountryLeaderboardPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  // カテゴリ、バージョン（デフォルト値はクエリパラメータから取得する）
  const [category, setCategory] = useState<Category>(() => {
    const categoryParam = search.get('category') || '';
    if (CATEGORIES.includes(categoryParam as any)) {
      return categoryParam as Category;
    }
    return DEFAULT_CATEGORY;
  });
  const [versionRange, setVersionRange] = useState<VersionRange>(() => {
    const versionParam = search.get('version') || '';
    if (VERSION_RANGES.includes(versionParam as any)) {
      return versionParam as VersionRange;
    }
    return DEFAULT_VERSION_RANGE;
  });

  // 上位何位まで含めるか
  const [combinedType, setCombinedType] = useState<CombinedType>(() => {
    const topParam = parseInt(search.get('top') || '');
    if (COMBINED_TYPES.includes(topParam as any)) {
      return topParam as CombinedType;
    }
    return DEFAULT_COMBINED_TYPES;
  });

  // 未確認のデータを含めるか
  const [runType, setRunType] = useState<RunType>(search.get('run') === 'cu' ? 'cu' : DEFAULT_RUN_TYPE);
  // ハイライトするユーザーをハッシュから取得する
  const [highlightedCountry, setHighlightedCountry] = useState(location.hash.replace('#', ''));

  // URLが変更されたらURLから各パラメータを取得する処理
  useEffect(() => {
    const search = new URLSearchParams(location.search);

    const _categoryParam = search.get('category') || '';
    const categoryParam = CATEGORIES.includes(_categoryParam as any) ? (_categoryParam as Category) : DEFAULT_CATEGORY;
    if (category !== categoryParam) {
      setCategory(categoryParam);
    }

    const _versionRangeParam = search.get('version') || '';
    const versionRangeParam = VERSION_RANGES.includes(_versionRangeParam as any)
      ? (_versionRangeParam as VersionRange)
      : DEFAULT_VERSION_RANGE;
    if (versionRange !== versionRangeParam) {
      setVersionRange(versionRangeParam);
    }

    const _combinedTypeParam = parseInt(search.get('top') || '');
    const combinedTypeParam = COMBINED_TYPES.includes(_combinedTypeParam as any)
      ? (_combinedTypeParam as CombinedType)
      : DEFAULT_COMBINED_TYPES;
    if (combinedType !== combinedTypeParam) {
      setCombinedType(combinedTypeParam);
    }

    const _runTypeParam = search.get('run') || '';
    const runTypeParam = RUN_TYPES.includes(_runTypeParam as any) ? (_runTypeParam as RunType) : DEFAULT_RUN_TYPE;
    if (runType !== runTypeParam) {
      setRunType(runTypeParam);
    }

    let highlightedUserParam = location.hash.replace('#', '');
    if (highlightedCountry !== highlightedUserParam) {
      setHighlightedCountry(highlightedUserParam);
    }
    setHighlightedCountry(location.hash.replace('#', ''));
  }, [location.search, location.hash, category, runType, highlightedCountry, versionRange, combinedType]);

  const replaceURL = useCallback(
    (
      newCategory: string,
      newVersionRange: VersionRange,
      newCombinedType: CombinedType,
      newRunType: RunType,
      newHilightedCountry: string,
    ) => {
      const params = new URLSearchParams();
      if (newCategory !== DEFAULT_CATEGORY) params.set('category', newCategory);
      if (newVersionRange !== DEFAULT_VERSION_RANGE) params.set('version', newVersionRange);
      if (newCombinedType !== DEFAULT_COMBINED_TYPES) params.set('top', newCombinedType.toString());
      if (newRunType !== DEFAULT_RUN_TYPE) params.set('run', newRunType);

      let modHilightedCountry = newHilightedCountry;
      if (newCategory !== category || newVersionRange !== versionRange || newRunType !== runType) {
        modHilightedCountry = '';
      }
      let path = params.toString();
      if (path !== '') path = `?${path}`;
      if (modHilightedCountry !== '') path = `${path}#${modHilightedCountry}`;

      history.push(`/cc${path}`);
    },
    [category, versionRange, runType, history],
  );

  const { data: countryList = [] } = useSWRImmutable(['fetchCountries', category, versionRange], fetchCountries, {});
  const { data: leaderboard, isValidating: isLoadingLeaderboard } = useSWRImmutable(
    ['fetchLeaderboard', category, versionRange, 'world'],
    fetchLeaderboard,
    {},
  );

  // 国別にまとめたアイテム一覧
  const combinedCountryLeaderboardItems = useMemo(() => {
    if (!leaderboard) {
      return [];
    }
    return getConbinedCountryLeaderboardItems(leaderboard, combinedType, runType === 'cu');
  }, [combinedType, leaderboard, runType]);

  const countriesCount = useMemo(
    () =>
      new Set(
        combinedCountryLeaderboardItems.filter((run) => run.items.length >= combinedType).map((run) => run.country),
      ).size,
    [combinedCountryLeaderboardItems, combinedType],
  );

  const categoryName = category === 'rsg' ? 'Random Seed' : 'Set Seed';
  const versionName = {
    '1.16+': '1.16+',
    '1.13-1.15': '1.13-1.15',
    '1.9-1.12': '1.9-1.12',
    '1.8': '1.8',
    'pre1.8': 'Pre 1.8',
  }[versionRange];

  const title = `Combined Country Ranking (Top ${combinedType}) - Any% Glitchless - ${categoryName} ${versionName} | Minecraft Speedrun Ranking`;

  // カテゴリ選択用
  const categoryOptions = useMemo(
    () => [
      { value: 'rsg', label: 'Random Seed' },
      { value: 'ssg', label: 'Set Seed' },
    ],
    [],
  );
  const categorySelected = useMemo(
    () => categoryOptions.find((c) => c.value === category),
    [categoryOptions, category],
  );

  // バージョン選択用
  const versionRangeOptions = useMemo(
    () => [
      { value: '1.16+', label: '1.16+' },
      { value: '1.13-1.15', label: '1.13-1.15' },
      { value: '1.9-1.12', label: '1.9-1.12' },
      { value: '1.8', label: '1.8' },
      { value: 'pre1.8', label: 'Pre 1.8' },
    ],
    [],
  );
  const versionRangeSelected = useMemo(
    () => versionRangeOptions.find((c) => c.value === versionRange),
    [versionRangeOptions, versionRange],
  );

  // バージョン選択用
  const combinedTypeOptions = useMemo(
    () => [
      { value: 1, label: '1' },
      { value: 3, label: '3' },
      { value: 8, label: '8' },
      { value: 16, label: '16' },
      { value: 32, label: '32' },
      { value: 64, label: '64' },
    ],
    [],
  );
  const combinedTypeSelected = useMemo(
    () => combinedTypeOptions.find((c) => c.value === combinedType),
    [combinedTypeOptions, combinedType],
  );

  // 未確認データの含めるかどうかの選択用
  const runTypeOptions = useMemo(
    () => [
      { value: 'vo', label: 'Only Verified Run' },
      { value: 'cu', label: 'Contains Unverified Run' },
    ],
    [],
  );
  const runTypeSelected = useMemo(() => runTypeOptions.find((c) => c.value === runType), [runTypeOptions, runType]);

  // ハンドラ

  const onChangeCategory = useCallback(
    (option: { value: Category; label: string }) => {
      if (category !== option.value) {
        replaceURL(option.value, versionRange, combinedType, runType, highlightedCountry);
      }
    },
    [category, replaceURL, versionRange, combinedType, runType, highlightedCountry],
  );

  const onChangeVersionRange = useCallback(
    (option: { value: VersionRange; label: string }) => {
      if (versionRange !== option.value) {
        replaceURL(category, option.value, combinedType, runType, highlightedCountry);
      }
    },
    [category, replaceURL, versionRange, combinedType, runType, highlightedCountry],
  );

  const onChangeCombinedType = useCallback(
    (option: { value: CombinedType; label: string }) => {
      if (combinedType !== option.value) {
        replaceURL(category, versionRange, option.value, runType, highlightedCountry);
      }
    },
    [category, replaceURL, versionRange, combinedType, runType, highlightedCountry],
  );

  const onChangeRunType = useCallback(
    (option: { value: RunType; label: string }) => {
      if (runType !== option.value) {
        replaceURL(category, versionRange, combinedType, option.value, highlightedCountry);
      }
    },
    [runType, replaceURL, category, versionRange, combinedType, highlightedCountry],
  );

  const onChangeHighligtedCountry = useCallback(
    (countryName: string) => {
      if (highlightedCountry !== countryName) {
        replaceURL(category, versionRange, combinedType, runType, countryName);
      }
    },
    [highlightedCountry, replaceURL, category, versionRange, combinedType, runType],
  );

  const isHilighted = useRef<boolean>(false);
  // 初回アクセス時のハイライト処理
  useEffect(() => {
    if (leaderboard === undefined || isHilighted.current || !highlightedCountry) {
      return;
    }

    isHilighted.current = true;
    setTimeout(() => {
      const element = document.getElementById(highlightedCountry);
      if (element === null) {
        return;
      }

      // 対象の要素を画面の上から3/4くらいの位置に来るようにスクロールさせる
      const windowHeight = document.documentElement.clientHeight;
      const elementScrollTop = element.getBoundingClientRect().top;
      const scrollToTop = Math.max(0, elementScrollTop - (windowHeight * 3) / 4);
      window.scrollTo({ top: scrollToTop, behavior: 'smooth' });
    }, 200);
    // 初期のhighlightedUserでのみ動かしたいのであえてdepsには含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaderboard]);

  // リーダーボードのパスを生成するハンドラ
  const getLeaderboardPath = useCallback(
    (country: string) => {
      const countryKey = countryList.find((c) => c.name === country)?.key ?? 'world';
      return createLeaderboardPath(category, versionRange, countryKey, runType);
    },
    [category, countryList, runType, versionRange],
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <GlobalStyle />
      <Wrapper>
        <StyledSwitch mode="spread">
          {({ OpenContents, switchContents }) => (
            <Header>
              <Title>
                Minecraft Java Edition
                <MobileOnly>
                  <br />
                </MobileOnly>
                <PCOnly> / </PCOnly>
                Any% Glitchless - <HeaderLink onClick={switchContents}>{categoryName}</HeaderLink>,{' '}
                <HeaderLink onClick={switchContents}>{versionName}</HeaderLink>
                <br />
                <Flag country="World" />
                &nbsp; Combined Country Ranking (<HeaderLink onClick={switchContents}>Top {combinedType}</HeaderLink>)
              </Title>
              <SwitchWrapper>
                <OpenContents>
                  <FilterContainer>
                    <FilterItem>
                      <FilterItemLabel>Top</FilterItemLabel>
                      <SelectWrapper>
                        <Select
                          options={combinedTypeOptions}
                          value={combinedTypeSelected}
                          onChange={onChangeCombinedType}
                        />
                      </SelectWrapper>
                    </FilterItem>
                    <FilterItem>
                      <FilterItemLabel>Category</FilterItemLabel>
                      <SelectWrapper>
                        <Select options={categoryOptions} value={categorySelected} onChange={onChangeCategory} />
                      </SelectWrapper>
                    </FilterItem>
                    <FilterItem>
                      <FilterItemLabel>Version</FilterItemLabel>
                      <SelectWrapper>
                        <Select
                          options={versionRangeOptions}
                          value={versionRangeSelected}
                          onChange={onChangeVersionRange}
                        />
                      </SelectWrapper>
                    </FilterItem>
                    <FilterItem>
                      <FilterItemLabel>Run Status</FilterItemLabel>
                      <SelectWrapper>
                        <Select options={runTypeOptions} value={runTypeSelected} onChange={onChangeRunType} />
                      </SelectWrapper>
                    </FilterItem>
                  </FilterContainer>
                </OpenContents>
              </SwitchWrapper>
            </Header>
          )}
        </StyledSwitch>
        <InfoContainer>
          <CountriesCount>🌏 {isLoadingLeaderboard ? '...' : countriesCount} Countries</CountriesCount>
          <Date>{leaderboard ? dayjs(leaderboard.date).format('YYYY-MM-DD HH:mm') : ''}</Date>
        </InfoContainer>
        <LeaderboardContainer>
          {isLoadingLeaderboard || leaderboard === undefined ? (
            <LoadingSpinner />
          ) : (
            <CombinedCountryLeaderboard
              items={combinedCountryLeaderboardItems}
              highlightedCountry={highlightedCountry}
              setHighlightedCountry={onChangeHighligtedCountry}
              getLeaderboardPath={getLeaderboardPath}
            />
          )}
        </LeaderboardContainer>
        <Footer />
      </Wrapper>
    </>
  );
};

export default CombinedCountryLeaderboardPage;

const Wrapper = styled.div`
  --table-width: 640px;

  width: 100%;
  max-width: var(--table-width);
  min-height: 100vh;
  margin: 0 auto;
  padding: 16px;

  font-family: 'Roboto', sans-serif;
  font-weight: 400;
`;

const StyledSwitch = styled(Switch)`
  margin-bottom: 32px;
`;

const Header = styled.div``;
const Title = styled.div`
  position: relative;
  font-size: 18px;
  line-height: 2;
  font-weight: bold;
`;

const HeaderLink = styled.span`
  display: inline-block;
  text-decoration: underline dotted;
  text-underline-offset: 0.2em;
  cursor: pointer;
`;

const SwitchWrapper = styled.div`
  position: relative;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 16px 0;
`;

const FilterItem = styled.div`
  max-width: 800px;
  width: 50%;
  display: flex;
  align-items: center;

  @media (max-width: 640px) {
    width: 100%;
  }
`;
const FilterItemLabel = styled.div`
  width: 120px;
`;

const SelectWrapper = styled.div`
  width: 100%;
  margin: 8px 0;

  input,
  select,
  textarea {
    font-size: 16px !important;
  }
`;

const LeaderboardContainer = styled.div`
  margin: 16px 0 65px;

  @media (max-width: 640px) {
    margin-left: -16px;
    margin-right: -16px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
`;

const CountriesCount = styled.div``;

const Date = styled.div`
  display: inline-block;
  color: #999;
  bottom: 2px;
  font-size: 14px;
  margin-left: auto;
`;

const MobileOnly = styled.span`
  display: none;
  @media (max-width: var(--table-width)) {
    display: initial;
  }
`;

const PCOnly = styled.span`
  @media (max-width: var(--table-width)) {
    display: none;
  }
`;
