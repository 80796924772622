import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';

import { fetchCountries, fetchLeaderboard } from 'api';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import { useLocation, useHistory } from 'react-router-dom';
import Select from 'react-select';
import VisibilitySensor from 'react-visibility-sensor';
import GlobalStyle from 'style';
import styled from 'styled-components';
import useSWRImmutable from 'swr/immutable';
import {
  CATEGORIES,
  Category,
  DEFAULT_CATEGORY,
  DEFAULT_COUNTRY,
  DEFAULT_RUN_TYPE,
  DEFAULT_VERSION_RANGE,
  RunType,
  RUN_TYPES,
  VersionRange,
  VERSION_RANGES,
  LeaderboardSortKey,
  SortDirection,
  DEFAULT_LEADERBOARD_SORT_KEY,
  DEFAULT_SORT_DIRECTION,
  LeaderboardSortCondition,
  LEADERBOARD_SORT_KEYS,
  LeaderboardItem,
  LEADERBOARD_SORT_KEY_MAPPING,
} from 'type';

import { Flag } from 'components/Flag';
import Footer from 'components/Footer';
import LoadingSpinner from 'components/LoadingSpinner';
import { Switch } from 'components/Switch';
import { createLeaderboardPath, getLeaderboardItems } from 'utils/leaderboard';

import { Leaderboard } from '../components/Leaderboard';
import { FLAGS } from '../utils/flag';

const LeaderboardPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  // ランキング対象の国、カテゴリ、バージョン（デフォルト値はクエリパラメータから取得する）
  const [country, setCountry] = useState(search.get('country') || DEFAULT_COUNTRY);
  const [category, setCategory] = useState<Category>(() => {
    const categoryParam = search.get('category') || '';
    if (CATEGORIES.includes(categoryParam as any)) {
      return categoryParam as Category;
    }
    return DEFAULT_CATEGORY;
  });
  const [versionRange, setVersionRange] = useState<VersionRange>(() => {
    const versionParam = search.get('version') || '';
    if (VERSION_RANGES.includes(versionParam as any)) {
      return versionParam as VersionRange;
    }
    return DEFAULT_VERSION_RANGE;
  });

  // 未確認のデータを含めるか
  const [runType, setRunType] = useState<RunType>(search.get('run') === 'cu' ? 'cu' : DEFAULT_RUN_TYPE);

  // リーダーボードのソート条件
  const [sortCondition, setSortCondition] = useState<LeaderboardSortCondition>([
    DEFAULT_LEADERBOARD_SORT_KEY,
    DEFAULT_SORT_DIRECTION,
  ]);
  const [sortKey, sortDirection] = sortCondition;

  // 詳細を表示するか
  const [showDetail, setShowDetail] = useState(false);

  // ハイライトするユーザーをハッシュから取得する
  const [highlightedUser, setHighlightedUser] = useState(location.hash.replace('#', ''));

  // フィルターするプレイヤー
  const [customTitle, setCustomTitle] = useState<string | undefined>(undefined);

  // フィルターするプレイヤー
  const [players, setPlayers] = useState<string[] | undefined>(undefined);

  // URLが変更されたらURLから各パラメータを取得する処理
  useEffect(() => {
    const search = new URLSearchParams(location.search);

    const countryParam = search.get('country') || DEFAULT_COUNTRY;
    if (country !== countryParam) {
      setCountry(countryParam);
    }

    const _categoryParam = search.get('category') || '';
    const categoryParam = CATEGORIES.includes(_categoryParam as any) ? (_categoryParam as Category) : DEFAULT_CATEGORY;
    if (category !== categoryParam) {
      setCategory(categoryParam);
    }

    const _versionRangeParam = search.get('version') || '';
    const versionRangeParam = VERSION_RANGES.includes(_versionRangeParam as any)
      ? (_versionRangeParam as VersionRange)
      : DEFAULT_VERSION_RANGE;
    if (versionRange !== versionRangeParam) {
      setVersionRange(versionRangeParam);
    }

    const _runTypeParam = search.get('run') || '';
    const runTypeParam = RUN_TYPES.includes(_runTypeParam as any) ? (_runTypeParam as RunType) : DEFAULT_RUN_TYPE;
    if (runType !== runTypeParam) {
      setRunType(runTypeParam);
    }

    const _sortParam = search.get('sort') || '';
    const _sortKeyParam = _sortParam.split(':')[0] as LeaderboardSortKey;
    const sortKeyParam = LEADERBOARD_SORT_KEYS.includes(_sortKeyParam) ? _sortKeyParam : DEFAULT_LEADERBOARD_SORT_KEY;
    const _sortDirectionParam = _sortParam.split(':')[1] as SortDirection;
    const sortDirectionParam =
      _sortDirectionParam === 'asc' || _sortDirectionParam === 'desc' ? _sortDirectionParam : DEFAULT_SORT_DIRECTION;
    if (sortKey !== sortKeyParam || sortDirection !== sortDirectionParam) {
      setSortCondition([sortKeyParam, sortDirectionParam]);
    }

    const detailParam = search.get('detail') === '1';
    if (showDetail !== detailParam) {
      setShowDetail(detailParam);
    }

    let highlightedUserParam = location.hash.replace('#', '');
    if (highlightedUser !== highlightedUserParam) {
      setHighlightedUser(highlightedUserParam);
    }
    setHighlightedUser(location.hash.replace('#', ''));

    let _customTitle: string | undefined = search.get('title') || undefined;
    if (customTitle !== _customTitle) {
      setCustomTitle(_customTitle);
    }

    let _players: string[] | undefined = (search.get('player') || '').split(',').filter((p) => p);
    _players = _players.length ? _players : undefined;
    // 同一かどうかの判定はソートした後に文字列化で行う
    if (players !== _players) {
      if (players === undefined || _players === undefined) {
        setPlayers(_players);
      } else if (players?.sort().join(',') !== _players.sort().join(',')) {
        setPlayers(_players);
      }
    }
  }, [
    location.search,
    location.hash,
    country,
    category,
    runType,
    highlightedUser,
    versionRange,
    sortKey,
    sortDirection,
    showDetail,
    players,
    customTitle,
  ]);

  const replaceURL = useCallback(
    ({
      category: newCategory,
      country: newCountry,
      versionRange: newVersionRange,
      runType: newRunType,
      highlightedUser: newHilightedUser,
      sortCondition: newSortCondition,
      showDetail: newShowDetail,
      customTitle: newCustomTitle,
      players: newPlayers,
    }: {
      category?: Category;
      country?: string;
      versionRange?: VersionRange;
      runType?: RunType;
      highlightedUser?: string;
      sortCondition?: LeaderboardSortCondition;
      showDetail?: boolean;
      customTitle?: string | null;
      players?: string[] | null;
    }) => {
      let _hilightedUser = newHilightedUser;
      const _category = newCategory || category;
      const _country = newCountry || country;
      const _versionRange = newVersionRange || versionRange;
      const _runType = newRunType || runType;
      const _sortCondition = newSortCondition || sortCondition;
      const _showDetail = newShowDetail ?? showDetail;
      const _customTitle = newCustomTitle === null ? undefined : newCustomTitle ?? customTitle;
      const _players = newPlayers === null ? undefined : newPlayers ?? players;
      if (
        _category !== category ||
        _country !== country ||
        _versionRange !== versionRange ||
        _runType !== runType ||
        _sortCondition[0] !== sortKey ||
        _sortCondition[1] !== sortDirection ||
        _showDetail !== showDetail ||
        _customTitle !== customTitle ||
        _players !== players
      ) {
        _hilightedUser = '';
      }

      const path = createLeaderboardPath(
        _category,
        _versionRange,
        _country,
        _runType,
        _hilightedUser,
        _sortCondition,
        _showDetail,
        _customTitle,
        _players,
      );
      history.push(path);
    },
    [
      category,
      country,
      versionRange,
      runType,
      sortCondition,
      showDetail,
      customTitle,
      players,
      sortKey,
      sortDirection,
      history,
    ],
  );

  const { data: countryList = [] } = useSWRImmutable(['fetchCountries', category, versionRange], fetchCountries, {});
  const { data: leaderboard, isValidating: isLoadingLeaderboard } = useSWRImmutable(
    ['fetchLeaderboard', category, versionRange, country],
    fetchLeaderboard,
    {},
  );

  // 未確認のrunを含む/含まないアイテム一覧
  const leaderboardItems = useMemo(() => {
    const items = leaderboard ? getLeaderboardItems(leaderboard, runType === 'cu', true) : [];

    // 対象プレイヤーをフィルタリング
    const filteredItems = items.filter((item) => {
      // プレイヤーフィルターがある場合はフィルタリング
      if (players && !players.includes(item.player.name)) {
        return false;
      }
      return true;
    });

    // 表示対象に絞ったカスタムランクを追加
    const customRankAddedPlayers = new Set<string>();
    const provisiontalCustomRankAddedPlayers = new Set<string>();
    const modifiedItems = filteredItems.map((item) => {
      const isVerified = item.rank !== null;
      let customRank: number | null = null;
      let provisionalCustomRank: number | null = null;

      if (isVerified) {
        if (!customRankAddedPlayers.has(item.player.name)) {
          customRankAddedPlayers.add(item.player.name);
          customRank = customRankAddedPlayers.size;
        }
        if (!provisiontalCustomRankAddedPlayers.has(item.player.name)) {
          provisiontalCustomRankAddedPlayers.add(item.player.name);
          provisionalCustomRank = provisiontalCustomRankAddedPlayers.size;
        }
      } else {
        if (!provisiontalCustomRankAddedPlayers.has(item.player.name)) {
          provisiontalCustomRankAddedPlayers.add(item.player.name);
          provisionalCustomRank = provisiontalCustomRankAddedPlayers.size;
        }
      }
      return { ...item, custom_rank: customRank, provisional_custom_rank: provisionalCustomRank };
    });

    // 表示しない項目をフィルタリング
    const filteredItems2 = modifiedItems.filter((item) => {
      // ソート対象がランクで、ソート方向がnull以外の場合、ランクがnullのものは表示しない
      if (sortKey === 'rank' && sortDirection !== null) {
        if (item.rank === null) {
          return false;
        }
      } else if (sortKey === 'provrank' && sortDirection !== null) {
        if (item.provisional_rank === null) {
          return false;
        }
      }

      return true;
    });

    let sortedItems = filteredItems2;
    if (sortDirection !== null) {
      const sortDirectionValue = sortDirection === 'asc' ? 1 : -1;

      const getValue = (item: LeaderboardItem, key: LeaderboardSortKey) => {
        switch (key) {
          case 'rank':
          case 'provrank':
          case 'localrank':
          case 'provlocalrank':
            return item[LEADERBOARD_SORT_KEY_MAPPING[key]];
          case 'player':
            return item.player.name;
          default:
            return item.record[LEADERBOARD_SORT_KEY_MAPPING[key]];
        }
      };

      sortedItems = Array.from(filteredItems2).sort((a, b) => {
        const aValue = getValue(a, sortKey);
        const bValue = getValue(b, sortKey);
        if (aValue === bValue) {
          return 0;
        } else if (aValue === null) {
          return 1;
        } else if (bValue === null) {
          return -1;
        } else {
          return aValue > bValue ? sortDirectionValue : -sortDirectionValue;
        }
      });
    }

    return sortedItems;
  }, [leaderboard, runType, sortDirection, players, sortKey]);

  const runnersCount = useMemo(() => new Set(leaderboardItems.map((run) => run.player.name)).size, [leaderboardItems]);

  const countryName = countryList.find((c) => c.key === country)?.name ?? 'World';
  const categoryName = category === 'rsg' ? 'Random Seed' : 'Set Seed';
  const versionName = {
    '1.16+': '1.16+',
    '1.13-1.15': '1.13-1.15',
    '1.9-1.12': '1.9-1.12',
    '1.8': '1.8',
    'pre1.8': 'Pre 1.8',
  }[versionRange];

  const title = `${
    countryName || 'World'
  } Ranking - Any% Glitchless - ${categoryName} ${versionName} | Minecraft Speedrun Ranking`;

  // カテゴリ選択用
  const categoryOptions = useMemo(
    () => [
      { value: 'rsg', label: 'Random Seed' },
      { value: 'ssg', label: 'Set Seed' },
    ],
    [],
  );
  const categorySelected = useMemo(
    () => categoryOptions.find((c) => c.value === category),
    [categoryOptions, category],
  );

  // 国選択用
  const countryOptions = useMemo(
    () =>
      countryList
        .sort((a, b) => {
          if (a === b) return 0;
          if (a.key === 'world') return -1;
          if (b.key === 'world') return 1;

          return a.key > b.key ? 1 : -1;
        })
        .map((c) => {
          const mapping = { 'RÃ©union': 'Reunion', 'QuÃ©bec': 'Québec' };
          const name = mapping[c.name] ?? c.name;
          const label = c.key === 'world' ? '🌏 World' : `${FLAGS[name]?.emoji || '　'} ${name}`;
          return { value: c.key, label, name };
        }),
    [countryList],
  );
  const countrySelected = useMemo(() => countryOptions.find((c) => c.value === country), [countryOptions, country]);

  // バージョン選択用
  const versionRangeOptions = useMemo(
    () => [
      { value: '1.16+', label: '1.16+' },
      { value: '1.13-1.15', label: '1.13-1.15' },
      { value: '1.9-1.12', label: '1.9-1.12' },
      { value: '1.8', label: '1.8' },
      { value: 'pre1.8', label: 'Pre 1.8' },
    ],
    [],
  );
  const versionRangeSelected = useMemo(
    () => versionRangeOptions.find((c) => c.value === versionRange),
    [versionRangeOptions, versionRange],
  );

  // 未確認データの含めるかどうかの選択用
  const runTypeOptions = useMemo(
    () => [
      { value: 'vo', label: 'Only Verified Run' },
      { value: 'cu', label: 'Contains Unverified Run' },
    ],
    [],
  );
  const runTypeSelected = useMemo(() => runTypeOptions.find((c) => c.value === runType), [runTypeOptions, runType]);

  // ハンドラ
  const onChangeCountry = useCallback(
    (option: { value: string; label: string }) => {
      if (country !== option.value) {
        replaceURL({ country: option.value });
      }
    },
    [country, replaceURL],
  );

  const onChangeCategory = useCallback(
    (option: { value: Category; label: string }) => {
      if (category !== option.value) {
        replaceURL({ category: option.value });
      }
    },
    [category, replaceURL],
  );

  const onChangeVersionRange = useCallback(
    (option: { value: VersionRange; label: string }) => {
      if (versionRange !== option.value) {
        replaceURL({ versionRange: option.value });
      }
    },
    [versionRange, replaceURL],
  );

  const onChangeRunType = useCallback(
    (option: { value: RunType; label: string }) => {
      if (runType !== option.value) {
        replaceURL({ runType: option.value });
      }
    },
    [runType, replaceURL],
  );

  const onChangeSortCondition = useCallback(
    (newSortCondition: LeaderboardSortCondition) => {
      if (sortKey !== newSortCondition[0] || sortDirection !== newSortCondition[1]) {
        replaceURL({ sortCondition: newSortCondition });
      }
    },
    [sortKey, sortDirection, replaceURL],
  );

  const onChangeShowDetail = useCallback(
    (newShowDetail: boolean) => {
      if (showDetail !== newShowDetail) {
        replaceURL({ showDetail: newShowDetail });
      }
    },
    [showDetail, replaceURL],
  );

  const onChangeHighligtedUser = useCallback(
    (userName: string) => {
      if (highlightedUser !== userName) {
        replaceURL({ highlightedUser: userName });
      }
    },
    [highlightedUser, replaceURL],
  );

  const isHilighted = useRef<boolean>(false);
  // 初回アクセス時のハイライト処理
  useEffect(() => {
    if (leaderboard === undefined || isHilighted.current || !highlightedUser) {
      return;
    }

    isHilighted.current = true;
    setTimeout(() => {
      const element = document.getElementById(highlightedUser);
      if (element === null) {
        return;
      }

      // 対象の要素を画面の上から3/4くらいの位置に来るようにスクロールさせる
      const windowHeight = document.documentElement.clientHeight;
      const elementScrollTop = element.getBoundingClientRect().top;
      const scrollToTop = Math.max(0, elementScrollTop - (windowHeight * 3) / 4);
      window.scrollTo({ top: scrollToTop, behavior: 'smooth' });
    }, 200);
    // 初期のhighlightedUserでのみ動かしたいのであえてdepsには含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaderboard]);

  // 詳細を表示するか
  const toggleShowDetail = useCallback(() => {
    onChangeShowDetail(!showDetail);
  }, [onChangeShowDetail, showDetail]);

  const [showSmallTitle, setShowSmallTitle] = useState(false);
  const onChangeHeaderIsVisible = useCallback((visible: boolean) => {
    setShowSmallTitle(!visible);
  }, []);

  const isCustomRanking = players !== undefined && players.length > 0;
  let leaderboardTitle: string | undefined = undefined;
  if (isCustomRanking) {
    leaderboardTitle = customTitle || `${countryName} Ranking (Custom)`;
  }

  const onClickClearCustomRanking = useCallback(() => {
    replaceURL({ players: null, customTitle: null });
  }, [replaceURL]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <GlobalStyle />
      <Wrapper showDetail={showDetail}>
        <HeaderContainer>
          <StyledSwitch mode="spread">
            {({ OpenContents, switchContents }) => (
              <Header>
                <VisibilitySensor onChange={onChangeHeaderIsVisible} partialVisibility={true}>
                  <Title>
                    Minecraft Java Edition
                    <MobileOnly>
                      <br />
                    </MobileOnly>
                    <PCOnly> / </PCOnly>
                    Any% Glitchless - <HeaderLink onClick={switchContents}>{categoryName}</HeaderLink>,{' '}
                    <HeaderLink onClick={switchContents}>{versionName}</HeaderLink>
                    <br />
                    {!isCustomRanking ? (
                      <>
                        <Flag country={countryName} />
                        &nbsp;
                        <HeaderLink onClick={switchContents}>{countryName}</HeaderLink> Ranking
                      </>
                    ) : (
                      <CustomTitleContainer>
                        {leaderboardTitle}
                        <ClearCustomRanking onClick={onClickClearCustomRanking}>[ clear ]</ClearCustomRanking>
                      </CustomTitleContainer>
                    )}
                  </Title>
                </VisibilitySensor>

                <SwitchWrapper>
                  <OpenContents>
                    <FilterContainer>
                      <FilterItem>
                        <FilterItemLabel>Category</FilterItemLabel>
                        <SelectWrapper>
                          <Select options={categoryOptions} value={categorySelected} onChange={onChangeCategory} />
                        </SelectWrapper>
                      </FilterItem>
                      <FilterItem>
                        <FilterItemLabel>Version</FilterItemLabel>
                        <SelectWrapper>
                          <Select
                            options={versionRangeOptions}
                            value={versionRangeSelected}
                            onChange={onChangeVersionRange}
                          />
                        </SelectWrapper>
                      </FilterItem>
                      <FilterItem>
                        <FilterItemLabel>Country</FilterItemLabel>
                        <SelectWrapper>
                          <Select options={countryOptions} value={countrySelected} onChange={onChangeCountry} />
                        </SelectWrapper>
                      </FilterItem>
                      <FilterItem>
                        <FilterItemLabel>Run Status</FilterItemLabel>
                        <SelectWrapper>
                          <Select options={runTypeOptions} value={runTypeSelected} onChange={onChangeRunType} />
                        </SelectWrapper>
                      </FilterItem>
                    </FilterContainer>
                  </OpenContents>
                </SwitchWrapper>
              </Header>
            )}
          </StyledSwitch>
        </HeaderContainer>
        <StickyHeaderContainer>
          <SmallTitle visible={showSmallTitle}>
            {!isCustomRanking ? (
              <>
                {categoryName}, {versionName} <Flag country={countryName} /> {countryName} Ranking
              </>
            ) : (
              <>{leaderboardTitle}</>
            )}
          </SmallTitle>
          <InfoContainer>
            <RunnersCount>🏃 {isLoadingLeaderboard ? '...' : runnersCount} Runners</RunnersCount>
            <Date onClick={toggleShowDetail}>
              {leaderboard ? (
                <>
                  {showDetail ? <span style={{ fontSize: '0.8em' }}>◀︎ </span> : ''}
                  {dayjs(leaderboard.date).format('YYYY-MM-DD HH:mm')}
                  {!showDetail ? <span style={{ fontSize: '0.8em' }}> ▶︎</span> : ''}
                </>
              ) : (
                ''
              )}
            </Date>
          </InfoContainer>
        </StickyHeaderContainer>
        <LeaderboardContainer>
          {isLoadingLeaderboard || leaderboard === undefined ? (
            <LoadingSpinner />
          ) : (
            <Leaderboard
              country={countrySelected?.name}
              items={leaderboardItems}
              highlightedUser={highlightedUser}
              setHighlightedUser={onChangeHighligtedUser}
              showLocalRank={countrySelected?.value !== 'world'}
              showDetail={showDetail}
              sortCondition={[sortKey, sortDirection]}
              setSortCondition={onChangeSortCondition}
              players={players}
            />
          )}
        </LeaderboardContainer>
        <Footer />
      </Wrapper>
    </>
  );
};

export default LeaderboardPage;

const Wrapper = styled.div<{ showDetail: boolean }>`
  --table-width: ${({ showDetail }) => (showDetail ? '1280px' : '640px')};

  width: 100%;
  max-width: var(--table-width);
  min-height: 100vh;
  margin: 0 auto;
  padding: 16px;

  font-family: 'Roboto', sans-serif;
  font-weight: 400;

  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  z-index: 30;
`;

const StyledSwitch = styled(Switch)`
  margin-bottom: 32px;
`;

const Header = styled.div``;
const Title = styled.div`
  position: relative;
  font-size: 18px;
  line-height: 2;
  font-weight: bold;
`;

const StickyHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  background: #fff;

  padding: 8px 16px 0;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -48px;

  z-index: 20;
`;

const SmallTitle = styled.div<{ visible: boolean }>`
  width: 100%;
  font-size: 16px;
  line-height: 3;
  font-weight: bold;
  overflow: visible;
  height: 48px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 200ms ease-in-out;
  white-space: nowrap;
`;

const HeaderLink = styled.span`
  display: inline-block;
  text-decoration: underline dotted;
  text-underline-offset: 0.2em;
  cursor: pointer;
`;

const SwitchWrapper = styled.div`
  position: relative;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 16px 0;
`;

const FilterItem = styled.div`
  max-width: 800px;
  width: 50%;
  display: flex;
  align-items: center;

  @media (max-width: 640px) {
    width: 100%;
  }
`;
const FilterItemLabel = styled.div`
  width: 120px;
`;

const SelectWrapper = styled.div`
  width: 100%;
  margin: 8px 0;

  input,
  select,
  textarea {
    font-size: 16px !important;
  }
`;

const LeaderboardContainer = styled.div`
  margin: -93px 0 65px;

  @media (max-width: 640px) {
    margin-left: -16px;
    margin-right: -16px;
  }

  /* overflow-x: scroll; */
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  z-index: 10;
`;

const InfoContainer = styled.div`
  padding: 8px 16px 16px;
  margin-left: -16px;
  margin-right: -16px;
  background: #fff;
  display: flex;
`;

const RunnersCount = styled.div``;

const Date = styled.div`
  display: inline-block;
  color: #999;
  bottom: 2px;
  font-size: 14px;
  margin-left: auto;
  cursor: pointer;
  min-width: 120px;
`;

const MobileOnly = styled.span`
  display: none;
  @media (max-width: var(--table-width)) {
    display: initial;
  }
`;

const PCOnly = styled.span`
  @media (max-width: var(--table-width)) {
    display: none;
  }
`;

const CustomTitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ClearCustomRanking = styled.div`
  text-decoration: underline dotted;
  text-underline-offset: 0.2em;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: normal;
  text-align: right;
  margin-left: 16px;
`;
