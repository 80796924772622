// 国
export const DEFAULT_COUNTRY = 'world';

// カテゴリ
export const CATEGORIES = ['rsg', 'ssg'] as const;
export type Category = typeof CATEGORIES[number];
export const DEFAULT_CATEGORY: Category = 'rsg';

// バージョン
export const VERSION_RANGES = ['1.16+', '1.9-1.12', '1.13-1.15', '1.8', 'pre1.8'] as const;
export type VersionRange = typeof VERSION_RANGES[number];
export const DEFAULT_VERSION_RANGE: VersionRange = '1.16+';

// タイプ
export const RUN_TYPES = ['vo', 'cu'] as const;
export type RunType = typeof RUN_TYPES[number];
export const DEFAULT_RUN_TYPE: RunType = 'cu';

// 上位何位まで含めるか
export const COMBINED_TYPES = [1, 3, 8, 16, 32, 64] as const;
export type CombinedType = typeof COMBINED_TYPES[number];
export const DEFAULT_COMBINED_TYPES: CombinedType = 3;

// リーダーボードのソートキー
export const LEADERBOARD_SORT_KEY_MAPPING = {
  player: 'player',
  rank: 'rank',
  provrank: 'provisional_rank',
  localrank: 'local_rank',
  provlocalrank: 'provisional_local_rank',
  customrank: 'custom_rank',
  provcustomrank: 'provisional_custom_rank',
  rta: 'real_time_t',
  igt: 'in_game_time_t',
  version: 'version',
  difficulty: 'difficulty',
  f3: 'f3',
  mods: 'mods',
  date: 'date',
  verify_date: 'verify_date',
};
export const LEADERBOARD_SORT_KEYS = Object.keys(
  LEADERBOARD_SORT_KEY_MAPPING,
) as (keyof typeof LEADERBOARD_SORT_KEY_MAPPING)[];
export type LeaderboardSortKey = typeof LEADERBOARD_SORT_KEYS[number];
export const DEFAULT_LEADERBOARD_SORT_KEY: LeaderboardSortKey = 'provrank';

export const SORT_DIRECTION = ['asc', 'desc', null] as const;
export type SortDirection = typeof SORT_DIRECTION[number];
export const DEFAULT_SORT_DIRECTION: SortDirection = null;

export type LeaderboardSortCondition = [LeaderboardSortKey, SortDirection];

export type CountryData = {
  key: string;
  name: string;
};

export type Player = {
  name: string;
  country: string | null;
};

export type Record = {
  id: string;
  real_time_t: number;
  in_game_time_t: number;
  version: string;
  difficulty: string;
  f3: string;
  mods: string;
  date: string;
  has_video: boolean;
  link: string;
  verify_date: string | null;
};

export type LeaderboardItem = {
  rank: number | null;
  provisional_rank: number | null;
  local_rank: number | null;
  provisional_local_rank: number | null;
  custom_rank?: number | null;
  provisional_custom_rank?: number | null;
  player: Player;
  record: Record;
};

export type Leaderbord = {
  type: 'RSG' | 'SSG';
  country: string;
  date: string;
  items: LeaderboardItem[];
};

export type CombinedCountryLeaderboardRecord = {
  real_time_t: number;
  in_game_time_t: number;
  date: string;
};

export type CombinedCountryLeaderboardItem = {
  rank: number;
  country: string;
  player_count: number;
  record: CombinedCountryLeaderboardRecord;
  items: LeaderboardItem[];
  is_valid: boolean;
};
